import {Moment} from "moment"
import PatientId from "./PatientId"

type EncounterDetails = {
    startTime: Moment
    endTime?: Moment
    patientId: PatientId
    status: EncounterStatus
}

export enum EncounterStatus {
    InProgress = "in-progress",
    Finished = "finished"
}

export default EncounterDetails