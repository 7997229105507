import { Button, Spinner } from "react-bootstrap"
import { useAuth0 } from "@auth0/auth0-react"

interface Props {
    children: React.ReactNode
}

const SignIn = ({ children }: Props) => {

    const auth = useAuth0()

    const signIn = async () => {
        try {
            await auth.loginWithRedirect()
        } catch (error) {
            console.log(error)
        }
    }

    return <div>
        {
            auth.isAuthenticated ?
                <>{children}</>
                :
                <div className="text-center py-3">
                    {auth.isLoading ?
                        <Spinner animation="border" /> :
                        <>
                            <Button onClick={() => signIn()}>Sign in</Button>
                        </>
                    }
                </div>
        }
    </div>
}

export default SignIn