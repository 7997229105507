import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback } from "react";

export function useApiRequest<T = any, R = AxiosResponse<T>>(): (requestConfig: AxiosRequestConfig) => Promise<R> {
    const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()

    return useCallback(async (requestConfig: AxiosRequestConfig) => {
        let backendUrl

        if (window.location.hostname === "localhost") {
            backendUrl = "//localhost:8080" // TODO 8080
        } else {
            backendUrl = "//backend." + window.location.host
        }

        await getAccessTokenSilently()
        const token = await getIdTokenClaims()

        return axios.request({ ...requestConfig, baseURL: backendUrl,  headers: { ...requestConfig.headers, "Authorization": `Bearer ${token?.__raw}` } })
    }, [getAccessTokenSilently, getIdTokenClaims])
}