import { useNavigate, useParams } from "react-router"
import { Button, Col, Row, Form, FloatingLabel, Spinner } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons"
import Patient from "./Patient"
import { useState } from "react"
import { useApiRequest } from "./UseApiRequest"

function PatientForm() {
    const params = useParams()
    const navigate = useNavigate()

    const apiRequest = useApiRequest()

    const patientId = params.patientId

    const creation = patientId === undefined

    const [data, setData] = useState<Patient>({ firstName: "", lastName: "", pesel: "", address: { line1: "", postcode: "", city: "" } })

    const [loading, setLoading] = useState(!creation)



    const fetchData = async () => {
        try {
            const response = await apiRequest({ url: `/v1/patients/${patientId}` })
            setData(response.data)
            setLoading(false)
        } catch (e) {
            console.error(e)
        }
    }

    const save = async () => {
        try {
            if (creation) {
                const response = await apiRequest({ method: "POST", url: `/v1/patients`, data })
                const id = response.data
                navigate(`/patients/${id}`)
            } else {
                await apiRequest({ method: "PUT", url: `/v1/patients/${patientId}`, data })
                goBack()
            }
        } catch (e) {
            console.error(e)
        }
    }

    const goBack = () => {
        if (patientId) {
            navigate(`/patients/${patientId}`)
        } else {
            navigate(`/patients`)
        }
    }

    if (loading) {
        fetchData()
    }

    return <>
        {
            creation ? <h1>New patient</h1> : <h1>Edit patient</h1>
        }
        {loading ? <div className="text-center"><Spinner animation="border" /></div> :
            <Form>
                <Row>
                    <Form.Group as={Col} controlId="name">
                        <FloatingLabel label="Imię" className="mb-3">
                            <Form.Control type="text" placeholder="Jan" defaultValue={data.firstName} onChange={e => setData({ ...data, firstName: e.target.value })}></Form.Control>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col} controlId="surname">
                        <FloatingLabel label="Nazwisko" className="mb-3">
                            <Form.Control type="text" placeholder="Kowalski" defaultValue={data.lastName} onChange={e => setData({ ...data, lastName: e.target.value })}></Form.Control>
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="pesel">
                        <FloatingLabel label="PESEL" className="mb-3">
                            <Form.Control type="text" placeholder="93010100011" defaultValue={data.pesel} onChange={e => setData({ ...data, pesel: e.target.value })}></Form.Control>
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <hr />
                <Row>
                    <Form.Group as={Col} controlId="line1">
                        <FloatingLabel label="Ulica, numer budynku/mieszkania" className="mb-3">
                            <Form.Control type="text" placeholder="Zielona" defaultValue={data.address.line1} onChange={e => setData({ ...data, address: { ...data.address, line1: e.target.value } })}></Form.Control>
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="postcode">
                        <FloatingLabel label="Kod pocztowy" className="mb-3">
                            <Form.Control type="text" placeholder="00-001" defaultValue={data.address.postcode} onChange={e => setData({ ...data, address: { ...data.address, postcode: e.target.value } })}></Form.Control>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col} controlId="city">
                        <FloatingLabel label="Miasto" className="mb-3">
                            <Form.Control type="text" placeholder="Warszawa" defaultValue={data.address.city} onChange={e => setData({ ...data, address: { ...data.address, city: e.target.value } })}></Form.Control>
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Button variant="danger" onClick={() => goBack()}><FontAwesomeIcon icon={faCancel} /> Cancel</Button>
                        {" "}
                        <Button variant="success" onClick={() => save()}><FontAwesomeIcon icon={faCheck} /> {creation ? "Add" : "Save"}</Button>
                    </Col>

                </Row>
            </Form>
        }
    </>
}

export default PatientForm