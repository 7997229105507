import moment from "moment"
import { Button } from "react-bootstrap"
import { useNavigate, useParams } from "react-router"
import { useApiRequest } from "./UseApiRequest"
import { EncounterStatus } from "./EncounterDetails"
import { LifecycleEventBus } from "./EventBus"

const ChooseEncounter = () => {

    const navigate = useNavigate()

    const patientId = useParams().patientId
    const apiRequest = useApiRequest()

    const createNewEncounter = async () => {
        try {
            const response = await apiRequest({method: "POST", url: `/v1/patients/${patientId}/encounters`})
            const encounterId = response.data
            LifecycleEventBus.encounterCreated({
                startTime: moment(),
                id: encounterId,
                status: EncounterStatus.InProgress,
                conditions: [],
                hasPrescription: false
            })
            navigate(`encounters/${encounterId}`) // TODO eventual consistency and refreshed view? :/
        } catch (e) {
            console.error(e)
            alert("An unknown error occurred")
        }
    }

    return <>

        <div className="text-center mt-2">
            <p>Wybierz wizytę z listy po lewej stronie lub <Button variant="success" className="align-baseline" onClick={() => createNewEncounter()}>stwórz nową wizytę</Button></p>
        </div>

    </>
}

export default ChooseEncounter