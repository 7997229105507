import { Auth0Provider } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";

interface OidcConfig {
    domain: string;
    clientId: string;
}

interface Props {
    children?: React.ReactNode
}

export function AsyncAuth0Provider({ children }: Props) {
    const [oAuth2Config, setOidcConfig] = useState<OidcConfig | null>(null)

    useEffect(() => {
        const baseUrl = window.location.hostname === "localhost" ? "//localhost:8080" : "//backend." + window.location.host

        axios.get(`${baseUrl}/v1/security/oidc-config`).then(response => setOidcConfig(response.data))
    }, [])

    if (oAuth2Config) {
        return <Auth0Provider domain={oAuth2Config.domain} clientId={oAuth2Config.clientId} useRefreshTokens={true} redirectUri={window.location.origin} cacheLocation="localstorage">{children}</Auth0Provider>
    } else {
        return <div className="py-3 text-center"><Spinner animation="border" /></div>
    }
}