import { useNavigate } from "react-router";
import { Button, Col, Container, ListGroup, Row, Spinner } from "react-bootstrap"
import { useState } from "react";
import PatientId from "./PatientId";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import Top from "./Top";
import { useApiRequest } from "./UseApiRequest";

function PatientList() {

    const navigate = useNavigate()

    type PatientWithId = {
        firstName: string
        lastName: string
        pesel: string
        id: PatientId
    }

    const apiRequest = useApiRequest()

    const [patients, setPatients] = useState(undefined as PatientWithId[] | undefined)

    const fetchPatients = async () => {
        const response = await apiRequest({ url: "/v1/patients" })
        const patients: PatientWithId[] = response.data.map((resourceWithId: any) => {
            return {
                id: resourceWithId.id,
                firstName: resourceWithId.resource.firstName,
                lastName: resourceWithId.resource.lastName,
                pesel: resourceWithId.resource.pesel
            }
        })
        setPatients(patients)
    }

    if (patients === undefined) {
        fetchPatients().catch(error => console.error(error))
    }

    return (<>
        <Top content={<Button variant="dark" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /> Home</Button>} />
        <Container>
            <Row className="py-3 justify-content-md-center">
                <Col md={6}>

                    {patients === undefined ? <div className="text-center pt-3"><Spinner animation="border" /></div> : // TODO centering
                        <ListGroup className="mt-3">
                            {
                                patients.map(patient => {
                                    return <ListGroup.Item key={patient.id} action onClick={() => navigate(`${patient.id}`)}><b>{patient.firstName} {patient.lastName}</b><br /><span>{patient.pesel}</span></ListGroup.Item>
                                })
                            }
                        </ListGroup>
                    }
                    <div className="text-center mt-3">
                        <Button variant="success" onClick={() => navigate("/patients/new")}><FontAwesomeIcon icon={faPlus} /> Nowy pacjent</Button>
                    </div>
                </Col>
            </Row>
        </Container>

    </>)
}

export default PatientList;