import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EncounterDetails from "./EncounterDetails"
import moment from "moment"
import ClinicalImpression from "./ClinicalImpression"
import { Button, Form, Spinner, Modal, Alert } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRotateBack, faCancel, faCheck, faTrash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { EncounterStatus } from "./EncounterDetails"
import PrescriptionsView from "./PrescriptionsView"
import { LifecycleEventBus } from "./EventBus"
import EncounterConditionsView, { Icd10Entry } from "./EncounterConditions"
import ReactRouterPrompt from "react-router-prompt"
import { useApiRequest } from "./UseApiRequest"

const EncounterDetailsView = () => {
    const params = useParams()
    const encounterId = params.encounterId
    const patientId = params.patientId

    const navigate = useNavigate()

    const apiRequest = useApiRequest()

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const closeDeleteModal = () => setShowDeleteModal(false)

    const [encounterDetails, setEncounterDetails] = useState<EncounterDetails | undefined>(undefined)
    const [clinicalImpression, setClinicalImpression] = useState<ClinicalImpression | undefined>(undefined)
    const [conditions, setConditions] = useState<Icd10Entry[]>([])

    const edition = encounterDetails ? encounterDetails.status === EncounterStatus.InProgress : false

    const [saving, setSaving] = useState(false)

    const [deleting, setDeleting] = useState(false)

    const [clinicalImpressionDraft, setClinicalImpressionDraft] = useState<ClinicalImpression | undefined>(clinicalImpression)

    const [conditionsDraft, setConditionsDraft] = useState<Icd10Entry[]>(conditions)

    const arrayEquals = (a: any[], b: any[]) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    useEffect(() => {
        const fetchEncounter = async () => {
            // TODO error handling?
            const response = await apiRequest({ url: `/v1/patients/${patientId}/encounters/${encounterId}` })
            setEncounterDetails({
                ...response.data,
                startTime: moment(response.data.startTime)
            })
        }

        const fetchClinicalImpression = async () => {
            // TODO one call instead of two separate?
            // TODO error handling?
            const response = await apiRequest({ url: `/v1/patients/${patientId}/encounters/${encounterId}/impression` })
            setClinicalImpression(response.data)
            setClinicalImpressionDraft(response.data)
        }

        const fetchConditions = async () => {
            try {
                const response = await apiRequest({ url: `/v1/patients/${patientId}/encounters/${encounterId}/conditions` })
                setConditions(response.data)
                setConditionsDraft(response.data)
            } catch (e) {
                console.error(e)
            }
        }

        fetchEncounter()
        fetchClinicalImpression()
        fetchConditions()
    }, [encounterId, patientId, apiRequest]);

    if (patientId && encounterId && encounterDetails && clinicalImpression && clinicalImpressionDraft) {
        const hasDraftChanged = (clinicalImpression.summary !== clinicalImpressionDraft.summary) || (clinicalImpression.content !== clinicalImpressionDraft.content) || !arrayEquals(conditions.map(c => c.code), conditionsDraft.map(c => c.code))

        const save = async () => {
            try {
                setSaving(true)
                await apiRequest({ method: "PUT", url: `/v1/patients/${patientId}/encounters/${encounterId}/impression`, data: clinicalImpressionDraft })
                setClinicalImpression(clinicalImpressionDraft)
                await apiRequest({ method: "PUT", url: `/v1/patients/${patientId}/encounters/${encounterId}/conditions`, data: conditionsDraft.map(condition => condition.code) })
                setConditions(conditionsDraft)

                setSaving(false)
                LifecycleEventBus.encounterChanged({
                    startTime: encounterDetails.startTime,
                    conditions: conditionsDraft,
                    hasPrescription: false, // TODO
                    status: EncounterStatus.InProgress,
                    id: encounterId
                })
            } catch (e) {
                console.error(e)
                setSaving(false)
            }
        }

        const deleteEncounter = async () => {
            if (encounterId) {
                try {
                    setDeleting(true)
                    await apiRequest({ method: "DELETE", url: `/v1/patients/${patientId}/encounters/${encounterId}` })
                    setShowDeleteModal(false)
                    setDeleting(false)
                    LifecycleEventBus.encounterDeleted(encounterId)
                    navigate("../")
                } catch (e) {
                    console.log(e)
                    setDeleting(false)
                }
            }
        }

        const updateStatus = async (status: EncounterStatus) => {
            try {
                setSaving(true)
                await apiRequest({ method: "PUT", url: `/v1/patients/${patientId}/encounters/${encounterId}/status`, data: JSON.stringify(status) })
                if (encounterDetails) {
                    setEncounterDetails({ ...encounterDetails, status: status })
                }
                LifecycleEventBus.encounterChanged({
                    startTime: encounterDetails.startTime,
                    status: status,
                    conditions: conditions,
                    hasPrescription: false, // TODO
                    id: encounterId
                })
                setSaving(false)
            } catch (e) {
                console.error(e)
                setSaving(false)
            }
        }

        const revertToSave = async () => {
            setClinicalImpressionDraft(clinicalImpression)
            setConditionsDraft(conditions)
        }

        return <>
            <ReactRouterPrompt when={hasDraftChanged}>
                {({ isActive, onConfirm, onCancel }) => (
                    // <ToastContainer className="p-3" position="top-end">
                    //     <Toast show={isActive} onClose={onCancel} bg="danger">
                    //         <Toast.Header>
                    //             <strong className="me-auto">Niezapisane zmiany</strong>
                    //         </Toast.Header>
                    //         <Toast.Body>Zapisz bądź anuluj zmiany przed wyjściem</Toast.Body>
                    //     </Toast>
                    // </ToastContainer>
                    <Alert show={isActive} onClose={onCancel} dismissible variant="danger">
                        <FontAwesomeIcon icon={faTriangleExclamation} /> Zapisz bądź anuluj zmiany przed wyjściem
                    </Alert>

                )}
            </ReactRouterPrompt>
            <Modal show={showDeleteModal} onHide={closeDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>All encounter data will be lost.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteModal} disabled={deleting}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteEncounter} disabled={deleting}>
                        <FontAwesomeIcon icon={faTrash} /> Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <>
                <div className="mb-3">
                    <>
                        {hasDraftChanged ?
                            <Button variant="outline-danger" onClick={() => revertToSave()}><FontAwesomeIcon icon={faCancel} /> Anuluj zmiany</Button> :
                            <Button variant="outline-secondary" onClick={() => navigate("../")}><FontAwesomeIcon icon={faArrowLeft} /> Powrót</Button>
                        }

                        {" "}
                        {edition ?
                            <>
                                <Button variant="success" onClick={() => save()} disabled={saving || !hasDraftChanged}><FontAwesomeIcon icon={faCheck} /> Zapisz</Button>
                                {" "}
                                <Button variant="primary" onClick={() => updateStatus(EncounterStatus.Finished)} disabled={hasDraftChanged}><FontAwesomeIcon icon={faCheck} /> Zakończ</Button>
                                {" "}
                                <Button variant="danger" onClick={() => setShowDeleteModal(true)} disabled={saving || hasDraftChanged}><FontAwesomeIcon icon={faTrash} /> Usuń</Button>
                            </> :
                            <>
                                <Button variant="secondary" onClick={() => updateStatus(EncounterStatus.InProgress)}><FontAwesomeIcon icon={faArrowRotateBack} /> Wznów</Button>
                            </>
                        }
                    </>


                </div>
                {clinicalImpression ? <>
                    <h3>Opis</h3>
                    {edition ? <Form.Control
                        as="textarea"
                        onChange={(e) => setClinicalImpressionDraft({ ...clinicalImpressionDraft, content: e.target.value })}
                        rows={8}
                        value={clinicalImpressionDraft.content}
                        disabled={saving} />
                        :
                        <p>{clinicalImpression.content}</p>
                    }
                    <h3>ICD 10</h3>
                    <EncounterConditionsView edition={edition} onChange={setConditionsDraft} conditions={conditionsDraft} disabled={saving} />
                    <h3>Recepty</h3>
                    <div>
                        {patientId && encounterId &&
                            <PrescriptionsView patientId={patientId} encounterId={encounterId} edition={edition} />
                        }
                    </div>
                </> : <><Spinner animation="border" /></>}
            </>
        </>
    } else {
        return <><Spinner animation="border" /></>
    }
}

export default EncounterDetailsView