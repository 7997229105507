import React from 'react';
import './index.css';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AsyncAuth0Provider } from './AsyncAuth0Provider';

const container = document.getElementById('root')
const root = createRoot(container!);
root.render(<BrowserRouter>
  <AsyncAuth0Provider>
    <App />
  </AsyncAuth0Provider>
</BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
