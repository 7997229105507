import { createEventBus, slot } from "ts-event-bus"
import EncounterListItem from "./EncounterListItem"

export const LifecycleEvents = {
    encounterCreated: slot<EncounterListItem>(),
    encounterChanged: slot<EncounterListItem>(),
    encounterDeleted: slot<string>(),
    sessionExpired: slot<void>(),
    signedOut: slot<void>()
}

export const LifecycleEventBus = createEventBus({
    events: LifecycleEvents
})