import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Button, Form, Modal, Spinner } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import Patient from "./Patient"
import { useApiRequest } from "./UseApiRequest"

function PatientDetailsView() {
    const patientId = useParams().patientId

    const [patient, setPatient] = useState<Patient | null>(null)

    const navigate = useNavigate()

    const apiRequest = useApiRequest()

    const [deleting, setDeleting] = useState(false)

    const [deletionConfirmed, setDeletionConfirmed] = useState(false)

    const expectedConfirmation = patient ? `${patient.firstName} ${patient.lastName}` : "confirm"

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const closeDeleteModal = () => setShowDeleteModal(false)

    const deletePatientRequest = useApiRequest()

    const deletePatient = async () => {
        try {
            setDeleting(true)
            await deletePatientRequest({ url: `/v1/patients/${patientId}` })

            await apiRequest({ method: "DELETE", url: `/v1/patients/${patientId}` })
            closeDeleteModal()
            setDeleting(false)
            navigate("/patients")
        } catch (e) {
            console.error(e)
            setDeleting(false)
        }
    }

    useEffect(() => {
        const fetchPatientDetails = async () => {
            const response = await apiRequest({ url: `/v1/patients/${patientId}` })
            const patient: Patient = response.data
            setPatient(patient)
        }

        fetchPatientDetails()
    }, [patientId, apiRequest])

    return <>
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>All patient data including encounters data will be deleted!</p>

                <p>To proceed, enter patient's name:
                    <pre>{expectedConfirmation}</pre>
                </p>
                <Form.Control type="text" placeholder="Patient's name" onChange={e => setDeletionConfirmed(e.target.value === expectedConfirmation)} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDeleteModal} disabled={deleting}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={deletePatient} disabled={deleting || !deletionConfirmed}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                </Button>
            </Modal.Footer>
        </Modal>

        {patient ? <>
            <h3>{patient.firstName} {patient.lastName}</h3>
            <h4>{patient.pesel}</h4>
            <h5>Address</h5>
            <p>
                {patient.address.line1}<br />
                {patient.address.postcode}&nbsp;{patient.address.city}
            </p>
            <div>
                <Button onClick={() => navigate("edit")} variant="secondary"><FontAwesomeIcon icon={faEdit} /> Edytuj</Button>
                {" "}
                <Button onClick={() => setShowDeleteModal(true)} variant="danger"><FontAwesomeIcon icon={faTrash} /> Usuń</Button>
            </div>

        </> : <div className="text-center"><Spinner animation="border" /></div>}

    </>
}

export default PatientDetailsView