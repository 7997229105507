import { useAuth0 } from "@auth0/auth0-react"
import { faHome, faSignOut, faStaffAesculapius } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactNode } from "react"
import { Button, Container, Navbar } from "react-bootstrap"
import { useNavigate } from "react-router"

interface Props {
    content?: ReactNode
}

const Top = ({content}: Props) => {
    const navigate = useNavigate()
    const auth = useAuth0()

    const signOut = () => {
        auth.logout({returnTo: window.location.origin})
    }

    return <Navbar bg="dark" variant="dark">
        <Container className="text-center">
            <span className="float-start">{content || <Button variant="dark" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /> Home</Button>}</span>
            <span className="text-light fs-5"><FontAwesomeIcon icon={faStaffAesculapius} /> ZdrowAśka</span>
            <Button variant="outline-dark text-light float-end" onClick={signOut}><FontAwesomeIcon icon={faSignOut} /> Sign out</Button>
        </Container>
    </Navbar>
}

export default Top