import './App.css';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import PatientsListView from './PatientListView';
import PatientForm from './PatientForm';
import PatientView from './PatientView';
import EncounterDetailsView from './EncounterDetailsView';
import { Col, Container, Row } from 'react-bootstrap';
import DropUploadWrapper from './DropUploadWrapper';
import ChooseEncounter from './ChooseEncounter';
import Top from './Top';
import SignIn from './SignIn';
import { useApiRequest } from './UseApiRequest';

interface PrescriptionUploadResult {
  patientId: string
  encounterId: string
}

const App = () => {
  const navigate = useNavigate()

  const apiRequest = useApiRequest()

  const uploadPrescription = async (file: File) => {
    const response = await apiRequest({ method: "PUT", url: `/v1/prescriptions`, data: file })
    return response.data as PrescriptionUploadResult
  }

  const uploadPrescriptions = async (files: File[]) => {
    const results = await Promise.all(files.map(uploadPrescription))

    if (results.length > 0) {
      const { patientId, encounterId } = results[0]

      navigate(`/patients/${patientId}/encounters/${encounterId}`)
    }
  }

  return (
    <div className="App">
      <SignIn>
        <div>
          <DropUploadWrapper onDrop={uploadPrescriptions}>
            <Routes>
              <Route path="patients" element={<PatientsListView />} />
              <Route path="patients/new" element={<><Top /><Container><Row><Col><PatientForm /></Col></Row></Container></>} />
              <Route path="patients/:patientId/edit" element={<><Top /><Container><Row><Col><PatientForm /></Col></Row></Container></>} />
              <Route path="patients/:patientId" element={<PatientView />}>
                <Route path="" element={<ChooseEncounter />} />
                <Route path="encounters/:encounterId" element={<EncounterDetailsView />} />
              </Route>
              <Route
                path="*"
                element={<Navigate to="/patients" replace />}
              />
            </Routes>
          </DropUploadWrapper>
        </div>
      </SignIn>
    </div>
  );
}

export default App;
