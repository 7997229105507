import { useCallback, useState } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { useDropzone } from "react-dropzone"

interface Props {
    onDrop: (files: File[]) => Promise<void>
    children?: React.ReactNode
}

const DropUploadWrapper = ({ onDrop, children }: Props) => {
    const [uploading, setUploading] = useState(false)

    const onDropInternal = useCallback(async (acceptedFiles: File[]) => {
        try {
            setUploading(true)
            await onDrop(acceptedFiles)
            setUploading(false)
        } catch (e) {
            console.error(e)
            setUploading(false)
        }
    }, [onDrop])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropInternal, noClick: true, noKeyboard: true })

    return <div {...getRootProps()} style={{opacity: isDragActive ? 0.5 : 1.0}} className="min-vh-100">
        <Modal show={uploading} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Importowanie...</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {uploading && <Spinner animation="border" />}
            </Modal.Body>
        </Modal>
        <input {...getInputProps()} />
        {children}
    </div>

}

export default DropUploadWrapper