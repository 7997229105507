import { useState } from "react"
import { Badge, ListGroup } from "react-bootstrap"
import { AsyncTypeahead, MenuItem, Token } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useApiRequest } from "./UseApiRequest"
import "./Typeahead.bs5.css"

export interface Icd10Entry {
    code: string
    description: string
}

interface Props {
    edition: boolean
    disabled: boolean
    conditions: Icd10Entry[]
    onChange: (entries: Icd10Entry[]) => void
}


const EncounterConditionsView = ({ edition, disabled, conditions, onChange }: Props) => {
    const [availableConditions, setAvailableConditions] = useState<Icd10Entry[]>([])
    const [availableConditionsLoading, setAvailableConditionsLoading] = useState(false)

    const apiRequest = useApiRequest()

    const searchConditions = (query: string, offset: number = 0) => {
        const limit = 50
        setAvailableConditionsLoading(true)
        apiRequest({ url: `/v1/icd10`, params: { "term": query, limit, offset } }).then(response => {
            setAvailableConditionsLoading(false)
            const newAvailableConditions = response.data.results
            setAvailableConditions(newAvailableConditions)
        }).catch(e => {
            console.error(e)
        })
    }

    return <>
        {edition ?
            <AsyncTypeahead
                id="encouonter-conditions"
                isLoading={availableConditionsLoading}
                labelKey={option => `${option.code}`}
                selected={conditions}
                onChange={onChange}
                filterBy={["code", "description"]}
                onSearch={searchConditions}
                disabled={disabled}
                multiple
                dropup={true} // FIXME
                options={availableConditions}
                renderToken={(option, props, idx) =>
                    <Token
                        disabled={props.disabled}
                        key={idx}
                        onRemove={props.onRemove}
                        option={option}
                        title={option.description}
                        tabIndex={props.tabIndex}>
                        {option.code}
                    </Token>
                }

                renderMenuItemChildren={(option, props, index) => {
                    return <MenuItem option={option} position={index} key={option.code} style={{ whiteSpace: "normal" }}>
                        <div className="d-flex align-items-stretch">
                            <div style={{ flex: "0 0 4em" }} className="me-1 mt-0"><Badge bg="secondary w-100">{option.code}</Badge></div>
                            <div style={{ marginTop: "0.1em" }} className="flex-fill">{option.description}</div>
                        </div>

                    </MenuItem>
                }}
                className="mb-2"
            /> :
            <ListGroup>{
                conditions.map(condition => {
                    return <ListGroup.Item key={condition.code}><div className="fw-bold">{condition.code}</div>{condition.description}</ListGroup.Item>
                })}
            </ListGroup>
        }
    </>

}

export default EncounterConditionsView