import { useEffect, useState } from "react"
import { Badge, Button, Col, Container, ListGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap"
import EncounterListItem from "./EncounterListItem"
import moment from "moment"
import { Outlet, useNavigate } from "react-router-dom"
import { useParams } from "react-router"
import PatientDetailsView from "./PatientDetailsView"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faChevronLeft, faClipboard, faFileSignature } from "@fortawesome/free-solid-svg-icons"
import { EncounterStatus } from "./EncounterDetails"
import Top from "./Top"
import { LifecycleEventBus } from "./EventBus"
import { useApiRequest } from "./UseApiRequest"

const PatientView = () => {
    const navigate = useNavigate()

    const selectedEncounterId: string | undefined = useParams().encounterId

    const apiRequest = useApiRequest()
    
    const patientId = useParams().patientId

    const [encounters, setEncounters] = useState<EncounterListItem[] | undefined>(undefined)

    useEffect(() => {
        const unsubscribeCreated = LifecycleEventBus.encounterCreated.on(createdEncounterListItem => {
            setEncounters([createdEncounterListItem, ...(encounters || [])])
        })

        const unsubscribeChanged = LifecycleEventBus.encounterChanged.on(changedEncounterListItem => {
            setEncounters(encounters?.map(encounter => {
                if (encounter.id === changedEncounterListItem.id) {
                    return changedEncounterListItem
                } else {
                    return encounter
                }
            }))
        })

        const unsubscribeDeleted = LifecycleEventBus.encounterDeleted.on(deletedEncounterId => {
            setEncounters(encounters?.filter((encounter,) => encounter.id !== deletedEncounterId) || [])
        })

        return () => {
            unsubscribeCreated()
            unsubscribeChanged()
            unsubscribeDeleted()
        }
    }, [encounters])

    useEffect(() => {
        const fetchEncounters = async () => {
            const response = await apiRequest({url: `/v1/patients/${patientId}/encounters`})
            setEncounters(response.data.map((encounter: any) => {
                return {
                    ...encounter,
                    startTime: moment(encounter.startTime),
                }
            }))
        }
        
        fetchEncounters()
    }, [patientId, apiRequest])

    return <>
        <Top content={<Button variant="dark" onClick={() => navigate("/patients")}><FontAwesomeIcon icon={faChevronLeft} /> Zmień pacjenta</Button>} />
        <Container>
            <Row className="py-3">
                <Col xs={3}>
                    {encounters ? <ListGroup>
                        {encounters.map(encounter => {
                            return <ListGroup.Item active={encounter.id === selectedEncounterId} key={encounter.id} action onClick={() => navigate(`encounters/${encounter.id}`)}>
                                {encounter.hasPrescription && <FontAwesomeIcon icon={faFileSignature} className="float-end" />}
                                <b>
                                    <FontAwesomeIcon icon={encounter.status === EncounterStatus.Finished ? faCheckCircle : faClipboard} style={{ width: "1em" }} />
                                    {" "}
                                    {encounter.startTime.format("DD.MM.YYYY")}
                                </b><br />
                                {encounter.conditions.map(condition => {
                                    return <OverlayTrigger
                                        key={condition.code}
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>
                                                {condition.description}
                                            </Tooltip>
                                        }
                                    ><Badge bg="secondary" className="me-1">{condition.code}</Badge></OverlayTrigger>
                                })}
                            </ListGroup.Item>
                        })}
                    </ListGroup> : <div className="text-center"><Spinner animation="border" /></div>}

                </Col>
                <Col xs={6}><Outlet /></Col>
                <Col xs={3}><PatientDetailsView /></Col>
            </Row>

        </Container>
    </>
}

export default PatientView