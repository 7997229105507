import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Button, Modal, Spinner } from "react-bootstrap"
import { useApiRequest } from "./UseApiRequest"

interface Props {
    patientId: string
    encounterId: string
    edition: boolean
}

interface Prescription {
    id: string
    drugDetails: DrugDetails
}

interface DrugDetails {
    text: Line[]
}

interface Line {
    parts: Part[]
}

interface Part {
    text: string
    styles: string[]
}

const PrescriptionsView = ({ patientId, encounterId, edition }: Props) => {

    const [data, setData] = useState<Prescription[]>([])

    const apiRequest = useApiRequest()

    const [loading, setLoading] = useState(true)
    const [deleting, setDeleting] = useState(false)

    const [prescriptionToDelete, setPrescriptionToDelete] = useState<string | undefined>(undefined)

    const deletePrescription = async () => {
        try {
            setDeleting(true)
            await apiRequest({ method: "DELETE", url: `/v1/patients/${patientId}/encounters/${encounterId}/prescriptions/${prescriptionToDelete}` })
            setData(data.filter((value,) => value.id !== prescriptionToDelete))
            setDeleting(false)
            closeDeleteModal()
        } catch (e) {
            console.error(e)
            setDeleting(false)
        }
    }

    const closeDeleteModal = () => {
        setPrescriptionToDelete(undefined)
    }

    useEffect(() => {
        const fetchPrescriptions = async () => {
            try {
                setLoading(true)
                const response = await apiRequest({ url: `/v1/patients/${patientId}/encounters/${encounterId}/prescriptions` })
                setData(response.data as Prescription[])
                setLoading(false)
            } catch (e) {
                console.error(e)
                setLoading(false)
            }

        }

        fetchPrescriptions()
    }, [patientId, encounterId, apiRequest])

    return <>
        <Modal show={prescriptionToDelete !== undefined} onHide={closeDeleteModal}>
            <Modal.Header closeButton>
                <Modal.Title>Czy na pewno usunąć tę receptę?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Ta operacja jest nieodwracalna.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDeleteModal} disabled={deleting}>
                    Anuluj
                </Button>
                <Button variant="danger" onClick={deletePrescription} disabled={deleting}>
                    <FontAwesomeIcon icon={faTrash} /> Usuń
                </Button>
            </Modal.Footer>
        </Modal>
        {
            loading ? <div className="text-center"><Spinner animation="border" /></div> : <>
                {data && <>
                    {data.length > 0 ?
                        data.map(prescription => {
                            return <div className="card mb-2" key={prescription.id}>
                                <div className="card-body">
                                    {edition && <Button variant="outline-danger" className="float-end" size="sm" onClick={() => setPrescriptionToDelete(prescription.id)}><FontAwesomeIcon icon={faTrash} /> Usuń</Button>}
                                    <span className="text-muted" style={{ "fontSize": "0.9em" }}>{prescription.id}</span>
                                    {prescription.drugDetails.text.map((text, paragraphIndex) => {
                                        return <div key={`${prescription.id}-${paragraphIndex}`}>
                                            {text.parts.map((part, partIndex) => {
                                                return <span className={part.styles.map(style => `cda-${style}`).join(" ")} key={`${prescription.id}-${paragraphIndex}-${partIndex}`}>{part.text} </span>
                                            })}
                                        </div>
                                    })}
                                </div>
                            </div>
                        }) :
                        <p className="text-center">Nie dodano recept</p>
                    }
                </>}
            </>
        }
    </>


}

export default PrescriptionsView